import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { getEmbeddedVideoLogsSelector } from '../../../../store/slices/application-selectors';
import styles from './VideoLogs.css';

const cx = classNames.bind(styles);

export function VideoLogs({ videoUniqId }: { videoUniqId: string }): ReactNode {
  const allLog = useSelector(getEmbeddedVideoLogsSelector);
  const logs =
    videoUniqId in allLog && Array.isArray(allLog[videoUniqId])
      ? allLog[videoUniqId]
      : [];

  return (
    <div className={cx('VideoLogs')}>
      {logs?.map((log, i) => {
        return <p key={`videologs-log-${i}`}>{log}</p>;
      })}
    </div>
  );
}
