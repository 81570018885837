import type { TPlayerStates } from '@canalplus/oneplayer-types';
import { OnePlayerPlatform, Platform } from '@dce-front/sdk-core';
import type { RefObject } from 'react';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { getDeviceTypeFromPlatform } from '../../../../helpers/application/application-helper';
import { useAppDispatch } from '../../../../helpers/hooks/useAppDispatch';
import { useInvariantSelector } from '../../../../helpers/hooks/useInvariantSelector';
import { setApplicationEmbeddedVideoLogs } from '../../../../store/slices/application';
import {
  getFeatureToggleMinimalPlayerDebug,
  getFeatureToggleMinimalPlayerForceWebPlatform,
  isIOsSelector,
  langKeySelector,
  offerLocationSelector,
  offerZoneSelector,
  platformSelector,
} from '../../../../store/slices/application-selectors';
import {
  resetValues,
  setIsEnded,
  setIsLoaded,
  setIsPaused,
} from '../../context/actions';
import { useVideoDispatch } from '../../context/hooks/useVideoDispatch';
import {
  getPlayerPlatform,
  isVideoEncrypted,
} from '../../helpers/video-helper';
import { useGetPlayerCredentials } from '../../hooks/useGetPlayerCredentials';
import type { VideoContent } from '../../types';

// TODO use typing from @canalplus/oneplayer-types when it will be available
export type ErrorEventFromMinimalPlayer = {
  detail?: {
    type?: string;
    fatal?: boolean;
    message?: string;
    details?: {
      code?: string;
      message?: string;
    };
  };
};

export type UseGetPlayerInstanceParams = {
  /**
   * The video content to be displayed
   */
  videoContent: VideoContent;
  /**
   * If true, displays subtitles
   */
  hasSubtitles?: boolean;
  /**
   * If true, the video will indefinitely loop
   */
  isLoop?: boolean;
  /**
   * If the value is changing, the player will be updated with the new one
   */
  isMuted: boolean;
  /**
   * To be performed when the video is playing in the Player
   */
  onVideoPlaying?: () => void;
  /**
   * To be performed when the video is loaded in the Player
   */
  onVideoLoaded?: () => void;
  /**
   * To be performed when the video is ending in the Player
   * E.g. used to launch the next video in promotion strates
   */
  onNaturalEnding?: () => void;
  /**
   * A unique identifier for the video
   */
  videoUniqId: string;
};

type UseGetPlayerInstanceReturn = {
  getPlayerInstance: () => any;
  getPlayerConfig: ({ autoplay }: { autoplay: boolean }) => any;
  playerContainerRef: RefObject<HTMLDivElement | null>;
  subtitlesContainerRef: RefObject<HTMLDivElement | null>;
};

export function useGetPlayerInstance({
  hasSubtitles = false,
  isLoop = false,
  isMuted,
  videoContent,
  onVideoLoaded,
  onVideoPlaying,
  onNaturalEnding,
  videoUniqId,
}: UseGetPlayerInstanceParams): UseGetPlayerInstanceReturn {
  const playerContainerRef = useRef<HTMLDivElement>(null);
  const subtitlesContainerRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const isIOS = useInvariantSelector(isIOsSelector);
  const platform = useSelector(platformSelector);
  const offerZone = useInvariantSelector(offerZoneSelector);
  const langKey = useInvariantSelector(langKeySelector);
  const offerLocation = useInvariantSelector(offerLocationSelector);
  const featMinimalPlayerForceWebPlatform = useInvariantSelector(
    getFeatureToggleMinimalPlayerForceWebPlatform,
  );
  const isFeatToggleMinimalPlayerDebug = useInvariantSelector(
    getFeatureToggleMinimalPlayerDebug,
  );
  const credentials = useGetPlayerCredentials();
  const videoDispatch = useVideoDispatch();
  const isEncrypted = isVideoEncrypted(videoContent);

  const getPlayerConfig = ({ autoplay }: { autoplay: boolean }) => {
    return {
      autoMode: true,
      content: isEncrypted ? videoContent.contentID : undefined,
      context: !featMinimalPlayerForceWebPlatform
        ? OnePlayerPlatform[platform]
        : OnePlayerPlatform.Web, // Force Web platform for minimal player on Simulator
      deviceType: !featMinimalPlayerForceWebPlatform
        ? getDeviceTypeFromPlatform(platform)
        : Platform.Web.toLowerCase(), // Force Web platform for minimal player on Simulator
      params: {
        autoplay,
        loop: isLoop,
        muted: isMuted,
        offerZone,
        preferredSubtitle: hasSubtitles ? undefined : null, // if undefined onePlayer will select display subtitles, if null onePlayer will not display subtitles
        startAt: { position: 0 },
        trailerPreviewMode: true,
        url: !isEncrypted ? videoContent.URLVideo : undefined,
      },
      platform: getPlayerPlatform(videoContent, isIOS),
      uiLanguage: `${langKey}-${String(offerLocation).toUpperCase()}`,
    };
  };

  const getPlayerInstance = () => {
    if (!(global as any).OnePlayerMinimal) {
      if (isFeatToggleMinimalPlayerDebug) {
        dispatch(
          setApplicationEmbeddedVideoLogs({
            id: videoUniqId,
            newLog: '[INFO]: OnePlayerMinimal is not defined',
          }),
        );
      }
      return undefined;
    }

    const playerConfig = getPlayerConfig({ autoplay: false });
    if (isFeatToggleMinimalPlayerDebug) {
      dispatch(
        setApplicationEmbeddedVideoLogs({
          id: videoUniqId,
          newLog: `[NEW]: new OnePlayerMinimal with ${JSON.stringify(playerConfig)}`,
        }),
      );
    }

    const instance = new (global as any).OnePlayerMinimal(
      playerContainerRef.current,
      playerConfig,
      credentials,
      subtitlesContainerRef.current,
    );

    instance.addEventListener('error', (value: ErrorEventFromMinimalPlayer) => {
      if (isFeatToggleMinimalPlayerDebug) {
        dispatch(
          setApplicationEmbeddedVideoLogs({
            id: videoUniqId,
            newLog: `[ERROR]: ${value}`,
          }),
        );
      }
      instance.destroy();
      videoDispatch(resetValues());
    });

    instance.addEventListener(
      'playerStateChange',
      (playerState: TPlayerStates) => {
        if (isFeatToggleMinimalPlayerDebug) {
          dispatch(
            setApplicationEmbeddedVideoLogs({
              id: videoUniqId,
              newLog: `[INFO]: OnePlayerMinimal playerState:"${playerState}" playerState event`,
            }),
          );
        }
        switch (playerState) {
          case 'LOADED': {
            const videoEl = playerContainerRef.current?.querySelector('video');
            if (videoEl) {
              videoEl.setAttribute('muted', `${isMuted}`);
              videoEl.setAttribute('playsinline', 'true');
            }
            videoDispatch(setIsLoaded(true));
            onVideoLoaded?.();
            break;
          }

          case 'PLAYING':
            videoDispatch(setIsPaused(false));
            onVideoPlaying?.();
            break;

          case 'PAUSED':
            videoDispatch(setIsPaused(true));
            break;

          case 'ENDED':
            if (!isLoop) {
              videoDispatch(setIsEnded(true));
              onNaturalEnding?.();
            }
            break;

          default:
            break;
        }
      },
    );

    return instance;
  };

  return {
    getPlayerInstance,
    getPlayerConfig,
    playerContainerRef,
    subtitlesContainerRef,
  };
}
