import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useScript } from 'usehooks-ts';
import { useAppDispatch } from '../../../../helpers/hooks/useAppDispatch';
import { useInvariantSelector } from '../../../../helpers/hooks/useInvariantSelector';
import Logger from '../../../../helpers/logger/logger-helper';
import { getOnePlayerMinimalUrl } from '../../../../helpers/url/url-helper';
import { setApplicationEmbeddedVideoLogs } from '../../../../store/slices/application';
import {
  getFeatureToggleMinimalPlayerDebug,
  platformSelector,
} from '../../../../store/slices/application-selectors';

type UseLoadOnePlayerMinimalScriptReturn = { isLoaded: boolean };

export function useLoadOnePlayerMinimalScript(
  videoUniqId: string,
): UseLoadOnePlayerMinimalScriptReturn {
  const dispatch = useAppDispatch();
  const isFeatToggleMinimalPlayerDebug = useInvariantSelector(
    getFeatureToggleMinimalPlayerDebug,
  );
  const platform = useSelector(platformSelector);
  const onePlayerMinimalSrc = getOnePlayerMinimalUrl(platform);

  const status = useScript(onePlayerMinimalSrc, {
    removeOnUnmount: false,
    id: 'onePlayerMinimal',
  });

  useEffect(() => {
    if (status === 'error') {
      Logger.error('[Video] load OnePlayerMinimal');
      if (isFeatToggleMinimalPlayerDebug) {
        dispatch(
          setApplicationEmbeddedVideoLogs({
            id: videoUniqId,
            newLog: `[ERROR]: load OnePlayerMinimal with url ${onePlayerMinimalSrc}`,
          }),
        );
      }
    }
  }, [
    dispatch,
    isFeatToggleMinimalPlayerDebug,
    onePlayerMinimalSrc,
    status,
    videoUniqId,
  ]);

  return { isLoaded: status === 'ready' };
}
